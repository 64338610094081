import React, { useContext } from "react";
import { Avatar, Badge, Box, Button, Grid, Paper, Typography } from "@mui/material";
import Rating from "@mui/material/Rating";
import ReviewRatingBar from "./ReviewRatingBar";
import { BAKER_PIC_FOLDER, STORAGE_BUCKET } from "../../common/constants";
import { UserContext } from "../../context/UserContext";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";

export default function Review(props) {
  const { user } = useContext(UserContext);

  let source = props.data.source;
  if (props.data.source === "goog") {
    source = "google";
  } else if (props.data.source === "fb") {
    source = "facebook";
  }

  return (
    <Paper elevation={0}>
      <Box px={2} py={2}>
        <Grid container spacing={1.5} alignItems="center">
          <Grid item>
            <Badge
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              overlap="circular"
              badgeContent={
                <Avatar
                  src={
                    props.data.destination === "google"
                      ? `${STORAGE_BUCKET}/img/icon/goog.jpg`
                      : `${STORAGE_BUCKET}/img/b/${user.acctId}/logo400.webp`
                  }
                  style={{ width: "24px", height: "24px" }}
                />
              }
            >
              <Avatar>{props.data.name.charAt(0).toUpperCase()}</Avatar>
            </Badge>
          </Grid>
          <Grid item>
            <Typography variant="body1">{props.data.name}</Typography>
            <Box>
              <Typography variant="overline" color="textSecondary">
                {moment(props.data.ts._seconds * 1000).format("MMM DD")}{" "}
                {/* props.data.orderId && <Link component={RouterLink} to={`/order/${props.data.orderId}`}>View Order</Link> */}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box py={2}>
          <Grid container spacing={1} alignItems={"center"}>
            <Grid item>
              <Rating precision={0.25} name="rating" value={Number(props.data.rating)} readOnly size="large" />
            </Grid>
            <Grid item>
              <Typography variant="h6">{props.data.rating}</Typography>
            </Grid>
          </Grid>
          <Box py={2}>
            <Grid container spacing={1}>
              <Grid item sm={8} xs={12}>
                <Grid container spacing={1}>
                  {Boolean(props.data.decoration) && (
                    <Grid item sm={6} xs={9}>
                      <Box>
                        <Typography variant="caption" color="textSecondary">
                          Decor
                        </Typography>
                        <ReviewRatingBar value={props.data.decoration} />
                      </Box>
                    </Grid>
                  )}
                  {Boolean(props.data.taste) && (
                    <Grid item sm={6} xs={9}>
                      <Box>
                        <Typography variant="caption" color="textSecondary">
                          Taste
                        </Typography>
                        <ReviewRatingBar value={props.data.taste} />
                      </Box>
                    </Grid>
                  )}
                  {Boolean(props.data.communication) && (
                    <Grid item sm={6} xs={9}>
                      <Box>
                        <Typography variant="caption" color="textSecondary">
                          Communication
                        </Typography>
                        <ReviewRatingBar value={props.data.communication} />
                      </Box>
                    </Grid>
                  )}
                  {Boolean(props.data.fulfillment) && (
                    <Grid item sm={6} xs={9}>
                      <Box>
                        <Typography variant="caption" color="textSecondary">
                          On-time
                        </Typography>
                        <ReviewRatingBar value={props.data.fulfillment} />
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Typography>{props.data.text}</Typography>
          {props.data.response && (
            <Paper elevation={0} style={{ backgroundColor: "#F7F7F7", marginTop: "1rem", padding: "1rem" }}>
              <Grid container spacing={1} style={{ flexWrap: "noWrap" }}>
                <Grid item>
                  <Avatar style={{ height: "20px", width: "20px" }} src={`${BAKER_PIC_FOLDER}/${user.id}/photo400.webp`} />
                </Grid>
                <Grid item>
                  <Box>
                    <Typography variant="caption" color="textSecondary">
                      {props.data.response}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          )}
        </Box>
        {props.showLinkToOrder && props.data.orderId && (
          <Button component={RouterLink} to={`/order/${props.data.orderId}`} fullWidth variant="outlined" color="secondary">
            View Order
          </Button>
        )}
      </Box>
      {/* <Box p={0.5} >
        {props.data.destination === "google" ? (
          <Alert severity="success">This customer chose to leave a review on your Google Business profile</Alert>
        ) : (
          <Alert severity="info">This customer chose not to leave a review on your Google Business profile</Alert>
        )}
      </Box> */}
    </Paper>
  );
}
