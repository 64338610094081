import React, { useState } from "react";
import { Box, FormControl, Grid, IconButton, TextField, Typography } from "@mui/material";
import PhoneField from "../../common/input/PhoneField";
import ShowMore from "../../common/component/ShowMore";
import { formatPhoneNumber } from "../../common/util";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import EmailInput from "../../common/component/EmailInput";

export default function CustomerDetailEntry(props) {
  const { customer } = props;

  const [mode, setMode] = useState(props.mode);

  if (customer && mode === "view") {
    return (
      <Box>
        <Typography variant="subtitle1">
          {customer.firstName} {customer.lastName}{" "}
          <IconButton size="small" onClick={() => setMode("edit")}>
            <ModeEditIcon />
          </IconButton>
        </Typography>
        <Typography variant="overline">{formatPhoneNumber(customer.phone)}</Typography>
      </Box>
    );
  } else {
    return (
      <Box mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                value={customer?.firstName}
                variant="outlined"
                fullWidth
                id="firstName"
                label="First Name"
                name="firstName"
                onChange={(e) => props.onChange({ ...customer, firstName: e.target.value })}
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                value={customer?.lastName}
                variant="outlined"
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                onChange={(e) => props.onChange({ ...customer, lastName: e.target.value })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl variant="outlined" fullWidth>
              <PhoneField name="Phone" value={customer?.phone} onChange={(phone) => props.onChange({ ...customer, phone })} required />
            </FormControl>
          </Grid>
        </Grid>
        <Box>
          <ShowMore fullWidth>
            <EmailInput value={customer?.email} onChange={(val) => props.onChange({ ...customer, email: val })} />
          </ShowMore>
        </Box>
      </Box>
    );
  }
}
