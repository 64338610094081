import React, { useEffect, useState } from "react";
import { getQueryParam, isPWA, getOS } from "../common/util";
import { auth, getDeviceToken } from "../firebase";
import bkstApi from "../api";
import { useNavigate } from "react-router";

export const UserContext = React.createContext();

export default ({ children }) => {
  const navigate = useNavigate();

  const [user, setUser] = useState("");
  const [toast, setToast] = useState("");

  const [loading, setLoading] = useState(true);

  // check utm params
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        me();
      } else {
        setLoading(false);
      }
    });
  }, []);

  const me = () => {
    bkstApi(`/me`).then(async (res) => {
      const user = res.data.user;
      if (user) {
        setUser(user);
        setLoading(false);

        const target = getQueryParam(window.location.search, "target");
        if (user.profile.status === "pending") {
          if (!user.plan) {
            navigate("/plan");
          } else {
            navigate("/onboarding");
          }
        } else if (target) {
          navigate(target);
        } else if (window.location.pathname === "/" || window.location.pathname === "/login") {
          if (user.role === "kitchen" || user.role === "kitchen leader") {
            navigate("/");
          } else {
            navigate("/dashboard");
          }
        }

        // check if PWA
        if (isPWA()) {
          //check if mobile
          const platform = getOS();
          if (platform === "iOS" || platform === "Android") {
            const token = await getDeviceToken();
            if (token) {
              bkstApi.post(`/fcm`, { token });
            }
          }
        }

        window.$zoho.salesiq.visitor.name(`${user.profile?.bizName} ${user.firstName ? `: ${user.firstName} ${user.lastName || ""}` : ""}`);
      }
    });
  };

  return <UserContext.Provider value={{ user, setUser, toast, setToast, loading }}>{children}</UserContext.Provider>;
};
