import React, { useEffect, useState } from "react";
import { Alert, AlertTitle, Box, Container, LinearProgress, Typography } from "@mui/material";
import Review from "../../review/component/Review";
import bkstApi from "../../api";
import moment from "moment";

export default function MyReviews(props) {
  const [reviews, setReviews] = useState([]);
  const [summary, setSummary] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    bkstApi(`/my/review`).then((res) => {
      let tmp = {
        [moment().format("MMM YYYY")]: 0,
        [moment().subtract(1, "month").format("MMM YYYY")]: 0,
        [moment().subtract(2, "month").format("MMM YYYY")]: 0
      };
      for (const o of res.data.reviews) {
        if (o.destination) {
          const date = moment(o.ts._seconds * 1000);
          const month = date.format("MMM YYYY");
          console.log("month", month);
          let count = +tmp[month];
          count = count + 1;
          tmp[month] = count;
        }
      }
      setSummary(tmp);
      setReviews(res.data.reviews.sort((o1, o2) => o2.ts?._seconds - o1.ts?._seconds));
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Container maxWidth="sm">
        <Box my={6}>
          <Box my={4}>
            <Typography variant="h1" gutterBottom>
              Reviews
            </Typography>
            <Typography variant="body1" color="textSecondary">
              BakeStreet automatically solicits feedback from your customers to help grow your online reputation
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "20px" }}>
            {Object.keys(summary).map((o) => (
              <Box p={3} sx={{ flexGrow: "1", backgroundColor: "#ECECEC", textAlign: "center" }}>
                <Typography variant="h3">{summary[o]}</Typography>
                <Typography variant="overline">{o}</Typography>
              </Box>
            ))}
          </Box>
          <Box my={4}>
            <Alert severity="info">
              <AlertTitle>More orders &harr; More Reviews</AlertTitle>Track all your orders on BakeStreet, so you're leveraging all your customers.
              More reviews help elevate your online presence and drive more business.
            </Alert>
          </Box>
          <Box>
            {reviews.map((el) => {
              return (
                <Box mb={6} key={el.id}>
                  <Review data={el} showLinkToOrder={true} />
                </Box>
              );
            })}
          </Box>
        </Box>
      </Container>
    );
  }
}
