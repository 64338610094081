import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Container, Grid, IconButton, LinearProgress, Typography } from "@mui/material";
import bkstApi from "../../api";
import currency from "currency.js";
import SearchIcon from "@mui/icons-material/Search";
import { UserContext } from "../../context/UserContext";
import BDatePicker from "../../common/input/BDatePicker";
import { useLocation } from "react-router-dom";
import { getCustomerName, getQueryParam } from "../../common/util";
import { useNavigate } from "react-router";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Link as RouterLink } from "react-router-dom";
import FeatureAccess from "../../baker/components/FeatureAccess";
import { equalsIgnoreCase } from "../../common/util";
import ItemFilter from "../../planner/component/ItemFilter";

const columns = [
  {
    field: "id",
    headerName: "Order",
    renderCell: (params) => (
      <IconButton component={RouterLink} to={`/order/${params.value}`}>
        <VisibilityOutlinedIcon />
      </IconButton>
    ),
    width: 50
  },
  {
    field: "time",
    headerName: "Timestamp",
    type: "datetime",
    valueGetter: ({ value }) => value && new Date(value),
    renderCell: (params) => (
      <div>
        <Box py={1}>
          <Typography variant="caption" display="block">
            {moment(params.value).format("ll")}
          </Typography>
          <Typography variant="overline" display="block" color="textSecondary">
            {moment(params.value).format("LT")}
          </Typography>
        </Box>
      </div>
    ),
    width: 120
  },
  {
    field: "name",
    headerName: "Customer",
    width: 160
  },
  {
    field: "total",
    headerName: "Total",
    type: "number",
    valueFormatter: ({ value }) => currency(value).format(),
    width: 100
  }
];

export default function SalesReport(props) {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  let location = useLocation();

  const [fromDt, setFromDt] = useState("");
  const [toDt, setToDt] = useState("");

  const [orders, setOrders] = useState([]);

  const [filter, setFilter] = useState();
  const [filteredResults, setFilteredResults] = useState([]);

  const [loading, setLoading] = useState(false);

  // Check params to identify search criteria
  useEffect(() => {
    let [qFrom, qTo] = [getQueryParam(window.location.search, "from"), getQueryParam(window.location.search, "to")];
    if (!qFrom || !qTo) {
      // reset dates
      qFrom = moment().subtract(7, "days").format("YYYY-MM-DD");
      qTo = moment().format("YYYY-MM-DD");
    }
    search(qFrom, qTo);
  }, [location.key]);

  useEffect(() => {
    if (toDt < fromDt) {
      setToDt("");
    }
  }, [fromDt]);

  useEffect(() => {
    let arr = [];
    arr = orders.filter((o) => {
      // check filters
      for (const [k, v] of Object.entries(filter)) {
        if (v.value) {
          if (k === "type") {
            if (v.value === "standard" && o.type !== "shop") {
              return false;
            } else if (v.value === "custom" && o.type === "shop") {
              return false;
            }
          } else if (k === "fulfillment" && !equalsIgnoreCase(o.fulfillment.type, v.value)) {
            return false;
          } else if (k === "store") {
            if (o.fulfillment.location) {
              if (!equalsIgnoreCase(o.fulfillment.location.street, v.value)) {
                return false;
              }
            } else if (!o.fulfillment.address?.toLowerCase().startsWith(v.value?.toLowerCase())) {
              // fallback to string address
              return false;
            }
          }
        }
      }
      // passed all filters
      return true;
    });
    setFilteredResults(arr);
  }, [filter, orders]);

  const runDateSearch = (e) => {
    e.preventDefault();
    navigate(`/report/sales?from=${fromDt}&to=${toDt}`);
  };

  const search = (fromDate, toDate) => {
    setLoading(true);
    bkstApi.get(`/my/report/sales?fromDt=${fromDate}&toDt=${toDate}`).then((res) => {
      setFilter("");
      setOrders(res.data.orders);

      setFromDt(fromDate);
      setToDt(toDate);

      setLoading(false);
    });
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Box>
        <Container maxWidth="sm">
          <FeatureAccess plan={["starter", "professional", "premium"]} />
          <Box>
            <Box mt={5}>
              <Typography variant="h2" gutterBottom>
                Sales Report
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Sales overview for a given time period
              </Typography>
              <Box py={2} my={3} sx={{ backgroundColor: "#FFFFFF", borderRadius: "4px" }}>
                <Box>
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <form onSubmit={runDateSearch}>
                        <Box py={2} align="center" sx={{ display: "flex", justifyContent: "center" }}>
                          <BDatePicker
                            value={fromDt}
                            label="From Date"
                            onChange={(d) => setFromDt(d)}
                            required
                            autoOk
                            disableFuture
                            hideIcon={true}
                          />
                          <BDatePicker value={toDt} label="To Date" onChange={(d) => setToDt(d)} required autoOk disableFuture hideIcon={true} />
                          <Button variant="contained" color="primary" type="submit" style={{ height: "56px" }}>
                            <SearchIcon />
                          </Button>
                        </Box>
                      </form>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Box>
          {orders && orders.length > 0 && (
            <Box>
              <Box my={2}>
                <ItemFilter
                  config={[
                    { name: "type", options: ["custom", "standard"] },
                    { name: "fulfillment", options: orders.map((o) => o.fulfillment?.type) },
                    filter?.["fulfillment"]?.value === "pickup"
                      ? {
                          name: "store",
                          options: orders.map((o) => o.fulfillment.type === "pickup" && o.fulfillment.location?.street)
                        }
                      : ""
                  ]}
                  data={orders}
                  onChange={(o) => {
                    let tmp = { ...filter, ...o };

                    // clear out store location filter if fulfillment is not pickup
                    if (tmp["store"]?.value) {
                      if (tmp["fulfillment"]?.value !== "pickup") {
                        delete tmp["store"];
                      }
                    }
                    setFilter(tmp);
                  }}
                />
              </Box>
              <Box my={2}>
                <Grid container spacing={2}>
                  <Grid item xs={6} align="center">
                    <Box px={2} pt={2} pb={1} style={{ backgroundColor: "#ECECEC" }}>
                      <Typography variant="h5">
                        {currency(
                          filteredResults.reduce((acc, o) => +acc + +(o.src === "mkt" ? o.payment.bakerTotal : o.payment.total), 0),
                          { precision: 0 }
                        ).format()}
                      </Typography>
                      <Typography variant="overline" color="textSecondary">
                        SALES
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} align="center">
                    <Box px={2} pt={2} pb={1} style={{ backgroundColor: "#ECECEC" }}>
                      <Typography variant="h5">{filteredResults.length}</Typography>
                      <Typography variant="overline" color="textSecondary">
                        ORDERS
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
          <Box sx={{ width: "100%", display: "grid" }}>
            <DataGrid
              rows={filteredResults.map((o) => {
                return {
                  id: o.id,
                  time: o.ts._seconds * 1000,
                  name: getCustomerName(o.customer),
                  total: o.src === "mkt" ? o.payment.bakerTotal : o.payment.total,
                  type: o.type
                };
              })}
              columns={columns}
              autoHeight
              getRowHeight={(params) => "auto"}
              disableRowSelectionOnClick
              initialState={{
                sorting: {
                  sortModel: [{ field: "time", sort: "desc" }]
                }
              }}
            />
          </Box>
        </Container>
      </Box>
    );
  }
}
