import React from "react";
import { Box, Paper } from "@mui/material";
import QuoteFooterInfo from "./QuoteFooterInfo";
import QuoteDescriptionV2 from "./QuoteDescriptionV2";

export default function QuoteItemGeneric(props) {
  const { request, quote, addEvent } = props;
  const status = props.quote.status;

  return (
    <Paper variant="outlined">
      {/* <Box px={2} py={2} textAlign="center">
        <QuoteFooterInfo status={status} expiration={quote.exdate} quote={quote} />
      </Box> */}
      <Box mx={2} my={2}>
        <QuoteDescriptionV2 status={status} quote={quote} request={request} addEvent={addEvent} />
      </Box>
    </Paper>
  );
}
