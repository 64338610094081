import React from "react";
import { Box, Button, Chip, Grid, Paper, Typography } from "@mui/material";
import Images from "../../request/components/Images";
import moment from "moment";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import { Link as RouterLink } from "react-router-dom";
import { bakeStreetSquareLogo } from "../../common/constants";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import currency from "currency.js";
import ScheduleSendOutlinedIcon from "@mui/icons-material/ScheduleSendOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";

export default function BakerQuoteSummary(props) {
  const { request } = props;

  let status = "";
  let total = "";

  if (request.quote?.status === "pending") {
    status = "pending";
  } else {
    status = "sent";
    if (request.quote.items) {
      total = request.quote.items.reduce((acc, o) => +acc + (+o.total || +o.price), 0);
    } else if (request.quote) {
      total = request.quote.quote;
    }
  }

  return (
    <Paper key={request.id} elevation={0}>
      <Box fontSize={16} px={2} pt={2} pb={1} mb={4}>
        <Grid container spacing={2}>
          <Grid item xs={4} sm={3}>
            <Images pics={request.pics || [request.pic1, request.pic2, request.pic3].filter((i) => i)} />
          </Grid>
          <Grid item xs={8} sm={9}>
            <Box>
              <Typography variant="h6" display="inline">
                {request.firstName ? `${request.firstName} ${request.lastName || ""}` : request.name}
              </Typography>
              {request.quote && request.quote.status === "paid" && (
                <Chip label="order" size="small" style={{ color: "#fff", backgroundColor: "#1abc9c" }} />
              )}
            </Box>
            <Box my={1}>
              <Typography style={{ display: "flex", alignItems: "center", fontSize: ".80rem", textTransform: "uppercase", color: "#888" }}>
                <EventOutlinedIcon style={{ fontSize: "1.2rem", marginRight: "7px" }} />
                {moment(request.date).format("ddd, MMM DD, YYYY")}
              </Typography>
            </Box>
            {status === "pending" && request.quote.reqTs && (
              <Box my={1}>
                <Typography style={{ display: "flex", alignItems: "center", fontSize: ".80rem", textTransform: "uppercase", color: "#888" }}>
                  <AccessTimeOutlinedIcon style={{ fontSize: "1.2rem", marginRight: "7px" }} />
                  {`Requested ${moment(request.quote.reqTs._seconds * 1000).fromNow()}`}
                </Typography>
              </Box>
            )}
            {status === "sent" && request.quote.resTs && (
              <Box my={1}>
                <Typography style={{ display: "flex", alignItems: "center", fontSize: ".80rem", textTransform: "uppercase", color: "#888" }}>
                  <ScheduleSendOutlinedIcon style={{ fontSize: "1.2rem", marginRight: "7px" }} />
                  {`Quoted ${moment(request.quote.resTs._seconds * 1000).fromNow()}`}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
        <Box mt={1}>
          {status === "pending" && (
            <Grid container alignItems="center">
              <Grid item xs={4} sm={3} align="center"></Grid>
              <Grid item sm={9} xs={8}>
                <Box sx={{ display: "flex", gap: "10px", justifyContent: "flex-end", alignItems: "center" }}>
                  {request.src === "mkt" && <img src={bakeStreetSquareLogo} style={{ width: "32px", height: "32px" }} />}
                  <Button disableElevation variant="contained" color="primary" component={RouterLink} to={`/quote/${request.id}`}>
                    Send Quote
                  </Button>
                </Box>
              </Grid>
            </Grid>
          )}
          {status === "sent" && (
            <Grid container alignItems="center">
              <Grid item xs={4} sm={3} align="center">
                <Typography variant="body1">{total ? currency(total).format() : "unavailable"}</Typography>
              </Grid>
              <Grid item sm={9} xs={8}>
                <Box sx={{ display: "flex", gap: "10px", justifyContent: "flex-end", alignItems: "center" }}>
                  {request.quote.autoQuote && <AutoAwesomeOutlinedIcon sx={{ color: "#999" }} />}
                  {request.src === "mkt" && <img src={bakeStreetSquareLogo} style={{ width: "32px", height: "32px" }} />}
                  <Button disableElevation variant="contained" color="secondary" component={RouterLink} to={`/quote/${request.id}`}>
                    View Quote
                  </Button>
                </Box>
              </Grid>
            </Grid>
          )}
          {status === "expired" && (
            <Grid container alignItems="center">
              <Grid item xs={4} sm={4} align="center">
                <Typography variant="body1">unavailable</Typography>
              </Grid>
              <Grid item sm={8} xs={8}>
                <Box sx={{ display: "flex", gap: "10px", justifyContent: "flex-end", alignItems: "center" }}>
                  {request.src === "mkt" && <img src={bakeStreetSquareLogo} style={{ width: "32px", height: "32px" }} />}
                  <Button disableElevation variant="outlined" color="secondary" component={RouterLink} to={`/quote/${request.id}`}>
                    View Quote
                  </Button>
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    </Paper>
  );
}
