import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import bkstApi from "../../api";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";

export default function MyCloseRequest() {
  const { reqId } = useParams();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [nogo, setNogo] = useState("");

  const submit = (e) => {
    e.preventDefault();

    bkstApi
      .put(`/my/request/${reqId}`, { status: "closed", nogo })
      .then((res) => {
        setOpen(false);
        navigate("/dashboard");
      })
      .catch((error) => {
        console.error("Failure");
      });
  };

  return (
    <span>
      <IconButton onClick={() => setOpen(true)}>
        <CloseOutlinedIcon />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth="sm">
        <form onSubmit={submit}>
          <DialogTitle>Why are you closing this quote request?</DialogTitle>
          <DialogContent>
            <Box py={2}>
              <Typography variant="body1" color="textSecondary" gutterBottom>
                Use this option only if you won't be sending a quote. Customer will NOT be notified.
              </Typography>
              <Box mt={4} pr={5}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="nogo-label">Reason</InputLabel>
                  <Select
                    value={nogo}
                    labelId="nogo-label"
                    label="Reason"
                    id="nogo"
                    name="nogo"
                    onChange={(e) => setNogo(e.target.value)}
                    autoWidth={true}
                    required
                  >
                    <MenuItem value="duplicate">Duplicate</MenuItem>
                    <MenuItem value="ordered">Order Placed</MenuItem>
                    <MenuItem value="cxl">Event Canceled</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Box align="right">
              <Button onClick={() => setOpen(false)} color="primary">
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit" style={{ marginLeft: "2rem" }}>
                Save
              </Button>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
    </span>
  );
}
