import React, { useEffect, useState, useRef } from "react";
import {
  Alert,
  Badge,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from "@mui/material";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { getQueryParam, sortByOrderDateTime, formatPhoneNumber, capitalize, getFormattedAddressAsArray } from "../../common/util";
import bkstApi from "../../api";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import BDatePicker from "../../common/input/BDatePicker";
import SearchIcon from "@mui/icons-material/Search";
import FulfillmentSummary from "../component/FulfillmentSummary";
import FulfillmentSlip from "../component/FulfillmentSlip";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import currency from "currency.js";
import { Link as RouterLink } from "react-router-dom";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import FeatureAccess from "../../baker/components/FeatureAccess";

export default function FulfillmentPlanner(props) {
  const navigate = useNavigate();
  let location = useLocation();

  const [fromDt, setFromDt] = useState("");
  const [toDt, setToDt] = useState("");

  const [fulfillmentType, setFulfillmentType] = useState("");

  const [storeLocation, setStoreLocation] = useState("");
  const [storeLocations, setStoreLocations] = useState([]);

  const [orders, setOrders] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);

  const [loading, setLoading] = useState(true);

  const printRef = useRef();

  const print = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `${fulfillmentType} Orders `
  });

  const handlePrint = () => {
    print();
  };

  // Check params to identify search criteria
  useEffect(() => {
    // get timeslots
    bkstApi(`/my/location`).then((res) => {
      setStoreLocations(res.data.locations);
    });

    let [qFrom, qTo] = [getQueryParam(window.location.search, "from"), getQueryParam(window.location.search, "to")];
    if (!qFrom || !qTo) {
      // reset dates
      qFrom = moment().format("YYYY-MM-DD");
      qTo = qFrom;
    }
    search(qFrom, qTo);
  }, [location.key]);

  useEffect(() => {
    if (toDt < fromDt) {
      setToDt("");
    }
  }, [fromDt]);

  useEffect(() => {
    if (fulfillmentType === "delivery") {
      setStoreLocation("");
    }
    setFilteredResults(
      orders.filter((o) => {
        if (fulfillmentType === "delivery") {
          return o.fulfillment.type === fulfillmentType;
        } else if (fulfillmentType === "pickup") {
          if (storeLocation) {
            return o.fulfillment.address.includes(storeLocation);
          } else {
            return o.fulfillment.type === fulfillmentType;
          }
        }
      })
    );
  }, [fulfillmentType, storeLocation]);

  const runDateSearch = (e) => {
    e.preventDefault();
    navigate(`/planning/fulfillment?from=${fromDt}&to=${toDt}`);
  };

  const search = (fromDate, toDate) => {
    setFulfillmentType("");
    setLoading(true);

    bkstApi.get(`/my/planning/fulfillment?fromDt=${fromDate}&toDt=${toDate}`).then((res) => {
      const sortedOrders = res.data.orders.sort(sortByOrderDateTime);

      setOrders(sortedOrders);
      setFilteredResults(sortedOrders);

      let [count, sales] = [0, 0];
      for (const o of res.data.orders) {
        count++;
        sales += +o.payment.total;
      }

      setFromDt(fromDate);
      setToDt(toDate);

      setLoading(false);
    });
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Container maxWidth="md">
        <FeatureAccess plan={["premium"]} />
        <Box>
          <Box my={5} align="center">
            <Typography variant="h3" gutterBottom>
              Fulfillment Planner
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Filter orders by fulfillment type (pickup, delivery) & store location
            </Typography>
            <Box my={2}>
              <Box>
                <Grid container>
                  <Grid item xs={12} sm={3}></Grid>
                  <Grid item xs={12} sm={6}>
                    <form onSubmit={runDateSearch}>
                      <Box py={2} align="center" sx={{ display: "flex", justifyContent: "center" }}>
                        <BDatePicker value={fromDt} label="From Date" onChange={(d) => setFromDt(d)} required autoOk hideIcon={true} />
                        <BDatePicker value={toDt} label="To Date" onChange={(d) => setToDt(d)} required autoOk hideIcon={true} />
                        <Button variant="contained" color="primary" type="submit" style={{ height: "56px" }}>
                          <SearchIcon />
                        </Button>
                      </Box>
                    </form>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
        {!loading && (
          <Box>
            {(!orders || orders.length === 0) && <Alert severity="error">No orders found within selected date range</Alert>}
            {orders && orders.length > 0 && (
              <Box my={4}>
                <ToggleButtonGroup
                  color="primary"
                  sx={{ textTransform: "none" }}
                  exclusive
                  value={fulfillmentType}
                  onChange={(e, v) => {
                    if (v) {
                      setFulfillmentType(v);
                    }
                  }}
                  fullWidth
                >
                  <ToggleButton value="delivery" fullWidth>
                    <Box mt={2} textAlign={"center"} sx={{ textTransform: "none" }}>
                      <Badge
                        color={fulfillmentType === "delivery" ? "primary" : "secondary"}
                        showZero={true}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right"
                        }}
                        badgeContent={orders.filter((o) => o.fulfillment.type === "delivery").length}
                      >
                        <LocalShippingOutlinedIcon sx={{ fontSize: "2rem" }} />
                      </Badge>
                      <Box>
                        <Typography variant="overline">Delivery</Typography>
                      </Box>
                    </Box>
                  </ToggleButton>
                  <ToggleButton value="pickup" fullWidth>
                    <Box mt={2} textAlign={"center"} sx={{ textTransform: "none" }}>
                      <Badge
                        color={fulfillmentType === "pickup" ? "primary" : "secondary"}
                        showZero={true}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right"
                        }}
                        badgeContent={orders.filter((o) => o.fulfillment.type === "pickup").length}
                      >
                        <LocalMallOutlinedIcon sx={{ fontSize: "2rem" }} />
                      </Badge>
                      <Box>
                        <Typography variant="overline">Pickup</Typography>
                      </Box>
                    </Box>
                  </ToggleButton>
                </ToggleButtonGroup>
                {fulfillmentType === "pickup" && (
                  <Box my={2}>
                    <ToggleButtonGroup
                      color="primary"
                      sx={{ textTransform: "none" }}
                      exclusive
                      value={storeLocation}
                      onChange={(e, v) => {
                        if (v) {
                          setStoreLocation(v);
                        }
                      }}
                      fullWidth
                    >
                      {storeLocations.map((o, idx) => {
                        return (
                          <ToggleButton value={o.address.street} fullWidth>
                            <Box mt={2}>
                              <Badge
                                color={storeLocation === o.address.street ? "primary" : "secondary"}
                                showZero={true}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "right"
                                }}
                                badgeContent={
                                  orders.filter((ord) => ord.fulfillment.type === "pickup" && ord.fulfillment.address.includes(o.address.street))
                                    .length
                                }
                              >
                                <StorefrontOutlinedIcon />
                              </Badge>
                              <Box>
                                <Typography variant="overline">{o.address.street}</Typography>
                              </Box>
                            </Box>
                          </ToggleButton>
                        );
                      })}
                    </ToggleButtonGroup>
                  </Box>
                )}
                <Box p={1} my={2} sx={{ backgroundColor: "#ECECEC" }}>
                  <Grid container alignItems={"center"}>
                    <Grid item xs={6}>
                      <Typography variant="overline" color="textSecondary">
                        showing {filteredResults.length} {capitalize(fulfillmentType)} orders.{" "}
                        {fulfillmentType === "delivery"
                          ? `Total Delivery Fee: ${currency(
                              filteredResults.reduce((acc, o) => {
                                return +acc + +o.payment.delivery;
                              }, 0)
                            ).format()}`
                          : ""}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} textAlign={"right"}>
                      {/* <IconButton>
                        <PrintOutlinedIcon />
                    </IconButton>*/}
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  {filteredResults.map((o, idx) => {
                    let address = getFormattedAddressAsArray(o.fulfillment.location, true);
                    if (address.length === 0) {
                      const addressArr = o.fulfillment.address.split(",");
                      const addressLine1 = addressArr.shift();
                      const addressLine2 = addressArr.join();

                      address.push(addressLine1);
                      address.push(addressLine2);
                    }

                    return (
                      <Paper elevation={0}>
                        <Box p={2} pr={0} mb={2}>
                          <Grid key={idx} container spacing={1}>
                            <Grid item sm={2} xs={12}>
                              <Box sx={{ display: "flex", gap: "7px" }}>
                                {o.fulfillment.type === "pickup" ? (
                                  <LocalMallOutlinedIcon sx={{ color: "#999" }} />
                                ) : (
                                  <LocalShippingOutlinedIcon sx={{ color: "#999" }} />
                                )}

                                <Box>
                                  <Typography variant="body2">{moment(o.fulfillment.date).format("ddd, MMM D")}</Typography>
                                  <Typography variant="overline" color="textSecondary">
                                    {o.fulfillment.time}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item sm={3} xs={12}>
                              {address.length > 0 && (
                                <Typography variant="body2" display="block">
                                  {address[0]}
                                </Typography>
                              )}
                              {address.length > 1 && (
                                <Typography variant="body2" display="block">
                                  {address[1]}
                                </Typography>
                              )}
                            </Grid>
                            <Grid item sm={3} xs={12}>
                              <Typography variant="body2">
                                {o.customer.name} {o.customer.lastName || ""}
                              </Typography>
                              <Typography variant="overline" color="textSecondary">
                                {formatPhoneNumber(o.customer.phone)}
                              </Typography>
                            </Grid>
                            <Grid item sm={2} xs={10}>
                              {fulfillmentType === "delivery" && (
                                <Box>
                                  {fulfillmentType === "delivery" && (
                                    <Typography variant="overline" color="textSecondary">
                                      {o.fulfillment.provider ? o.fulfillment.provider.name : "In-House"} Delivery
                                    </Typography>
                                  )}
                                  <Typography variant="overline" color="textSecondary" display="block">{`${currency(
                                    o.payment.delivery
                                  ).format()}`}</Typography>
                                </Box>
                              )}
                              <Typography variant="caption" color="textSecondary" display="block">
                                {o.fulfillment.instructions}
                              </Typography>
                            </Grid>
                            <Grid item sm={2} xs={2} textAlign={"right"}>
                              <IconButton component={RouterLink} to={`/order/${o.id}`}>
                                <VisibilityOutlinedIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Box>
                      </Paper>
                    );
                  })}
                  {/* <DataGrid
              sx={{
                "& .MuiDataGrid-cell": {
                  padding: "10px"
                }
              }}
              disableColumnFilter
              rows={orders.map((o) => {
                const addressArr = o.fulfillment.address.split(",");
                const addressLine1 = addressArr.shift();
                const addressLine2 = addressArr.join();

                return {
                  id: o.id,
                  date: o.fulfillment.date,
                  time: o.fulfillment.time,
                  addressLine1,
                  addressLine2,
                  instructions: o.fulfillment.instructions,
                  name: getCustomerName(o.customer),
                  phone: o.customer.phone,
                  deliveryFee: o.payment.delivery,
                  description: o.details.title
                };
              })}
              columns={columns}
              autoHeight
              getRowHeight={() => "auto"}
              disableRowSelectionOnClick
              initialState={{
                sorting: {
                  sortModel: [{ field: "time", sort: "desc" }]
                }
              }}
            /> */}
                  <Box style={{ overflow: "hidden", height: "0" }}>
                    <Box ref={printRef}>
                      <FulfillmentSummary orders={filteredResults} fulfillmentType={fulfillmentType} />
                      <div class="pagebreak"> </div>
                      {filteredResults.map((o, idx) => {
                        return (
                          <Box>
                            <FulfillmentSlip order={o} />
                            {filteredResults.length > +idx + 1 && <div class="pagebreak"> </div>}
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                  {fulfillmentType && filteredResults.length > 0 && (
                    <Box my={2} textAlign="center">
                      <Button size="large" fullWidth startIcon={<PrintOutlinedIcon />} variant="contained" color="primary" onClick={handlePrint}>
                        PRINT
                      </Button>
                      <Typography variant="caption" color="textSecondary">
                        Print summary of {capitalize(fulfillmentType)} orders, plus {capitalize(fulfillmentType)} slips for each order.
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Container>
    );
  }
}
