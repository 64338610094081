import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

export default function Quantity(props) {
  const { value } = props;

  const validateQuantity = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };

  const handleQuantityChange = (change) => {
    const newValue = +value + +change;
    if (newValue > 0) {
      props.onChange(newValue);
    }
  };

  return (
    <Box sx={{ display: "flex", gap: "30px", alignItems: "center" }}>
      <TextField
        type="number"
        name="quantity"
        label="Quantity"
        value={value}
        variant="outlined"
        id="quantity"
        onFocus={e => e.target.select()} 
        onChange={(e) => props.onChange(e.target.value)}
        inputProps={{
          sx: { textAlign: "center" }
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton onClick={() => handleQuantityChange(-1)} disabled={value === 1}>
                <RemoveIcon />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => handleQuantityChange(1)}>
                <AddIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
        sx={{
          width: "160px",
          "& .MuiOutlinedInput-root": {
            paddingRight: 0,
            paddingLeft: 0
          },
          backgroundColor: "#FFFFFF"
        }}
        onKeyPress={validateQuantity}
      />
    </Box>
  );
}
