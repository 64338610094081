import React, { useContext, useState, useEffect, useRef } from "react";
import { Badge, Box, Menu, MenuItem, Grid, IconButton, Typography } from "@mui/material";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import moment from "moment";
import NotificationIcon from "./NotificationIcon";
import { useNavigate } from "react-router-dom";
import bkstApi from "../../api";
import { Link as RouterLink } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import { UserContext } from "../../context/UserContext";

const menuId = "notif";
export default function Notifications(props) {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);

  const isMenuOpen = Boolean(anchorEl);

  const menuRef = useRef(anchorEl);
  menuRef.current = anchorEl;

  useEffect(() => {
    const q = query(collection(db, `notification`), where("uid", "==", user.uid), where("status", "==", "open"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      //if (isMenuOpen) {
      // don't update if view is open
      //} else {
      const data = [];
      let recentOrders = [];
      let recentQuoteRequests = [];

      querySnapshot.forEach((doc) => {
        let o = doc.data();
        o.id = doc.id;

        if (o.link.includes("/order/") && (o.heading.includes("Order for") || o.heading.includes("New Order"))) {
          recentOrders.push(o);
        } else if (o.link.includes("/quote/") && o.heading.includes("New Quote Request")) {
          recentQuoteRequests.push(o);
        } else {
          data.push(o);
        }
      });

      if (recentOrders.length > 0) {
        data.push({
          heading: `${recentOrders.length} New Orders`,
          text: "Click to view",
          priority: "1",
          link: "/order?search=recent",
          status: "open",
          ids: recentOrders.map((ro) => ro.id)
        });
      }

      if (recentQuoteRequests.length > 0) {
        data.push({
          heading: `${recentQuoteRequests.length} New Quote Requests`,
          text: "Click to view",
          priority: "8",
          link: "/quote?filter=pending",
          status: "open",
          ids: recentQuoteRequests.map((ro) => ro.id)
        });
      }

      setNotifications(data.sort(sort));
      //}
    });
  }, []);

  const sort = (o1, o2) => {
    if (o1.priority > o2.priority) {
      return 1;
    } else if (o1.priority < o2.priority) {
      return -1;
    } else {
      return o1.link.localeCompare(o2.link);
    }
  };

  const clearNotification = (e, item) => {
    e.stopPropagation();
    e.preventDefault();

    let arr = notifications.filter((x) => x.id !== item.id);
    setNotifications(arr);

    bkstApi.put(`/my/notification`, { ids: item.ids || [item.id], markAs: "read" });
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const markRead = (item) => {
    handleMenuClose();
    bkstApi.put(`/my/notification`, { ids: item.ids || [item.id], markAs: "read" });
  };

  return (
    <span>
      <IconButton onClick={handleProfileMenuOpen}>
        <Badge badgeContent={notifications.length} color="error">
          <NotificationsActiveOutlinedIcon />
        </Badge>
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <Box pl={2} py={1} sx={{ borderBottom: "1px solid #DDD", marginBottom: "1em" }}>
          <Typography variant="h6">Unread Notifications</Typography>
        </Box>
        {notifications.length === 0 && (
          <MenuItem onClick={handleMenuClose} sx={{ color: "#1abc9c", justifyContent: "center" }}>
            <Box align="center" mt={1} mb={3}>
              <CheckCircleOutlineOutlinedIcon style={{ fontSize: "3rem" }} />
              <Typography>Nothing Unread</Typography>
            </Box>
          </MenuItem>
        )}
        {notifications.map((item) => (
          <MenuItem key={item.id} component={RouterLink} to={item.link} sx={{ paddingRight: "0px" }}>
            <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
              <Box pt={0.5} onClick={handleMenuClose}>
                <Badge
                  badgeContent={item.count}
                  color="secondary"
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                  }}
                >
                  <NotificationIcon type={item.heading} priority={item.priority} acctId={item.acctId} />
                </Badge>
              </Box>
              <Box sx={{ maxWidth: "220px", flexGrow: "1", overflow: "hidden" }} onClick={(e) => markRead(item)}>
                <Typography
                  variant="body2"
                  style={{
                    whiteSpace: "normal",
                    display: "-webkit-box",
                    "-webkit-line-clamp": "2",
                    "-webkit-box-orient": "vertical",
                    overflow: "hidden"
                  }}
                >
                  {item.heading}
                </Typography>
                <Typography
                  title={item.text}
                  variant="body2"
                  color="textSecondary"
                  style={{
                    whiteSpace: "normal",
                    display: "-webkit-box",
                    "-webkit-line-clamp": "3",
                    "-webkit-box-orient": "vertical",
                    overflow: "hidden"
                  }}
                >
                  {item.text}
                </Typography>
                {item.ts && (
                  <Box mt={0.5} style={{ fontSize: ".8rem", color: "#888" }}>
                    <Grid container alignItems="center">
                      <Grid item>
                        <AccessTimeOutlinedIcon style={{ fontSize: "14px", marginRight: "5px" }} />
                      </Grid>
                      <Grid item>{`${moment(item.ts.seconds * 1000).fromNow()}`}</Grid>
                    </Grid>
                  </Box>
                )}
              </Box>
              <Box>
                <IconButton onClick={(e) => clearNotification(e, item)}>
                  <CloseIcon sx={{ color: "#999", fontSize: "16px" }} />
                </IconButton>
              </Box>
            </Box>
          </MenuItem>
        ))}
        <MenuItem component={RouterLink} to="/notification" onClick={handleMenuClose} sx={{ borderTop: "1px solid #DDD", justifyContent: "center" }}>
          <Typography variant="overline">see all notifications</Typography>
        </MenuItem>
      </Menu>
    </span>
  );
}
